<template>
  <section :class="['newsArtical-page', dir_ar]" v-if="!loading">
    <div
      class="artical-cover"
      :style="{ backgroundImage: `url(${getVNews.image_url != null ? getVNews.image_url : placeholder})` }"
      v-if='getVNews.image_url'
    ></div>
    <div
      class="artical-cover"
      :style="{ backgroundImage: `url(${placeholder})` }"
      v-else
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-12">
          <div class="date mt-5 mb-2 text-pri">
            {{ getVNews.created_at.match(/(\d{4}-\d{2}-\d{1,2}).*/)[1]}}
          </div>
          <div class="title">
            <h2 class="fw-bold">
              {{ getVNews.titles[lang] }}
            </h2>
          </div>
          <div class="artical-text text-desc" v-html="getVNews.descriptions[lang]"></div>
        </div>
        <div class="col-md-3 col-12 mt-5">
          <button class="btn-share mx-auto btn-pri" @click="toggleShareBox">
            <i class="fa fa-share-alt"></i> 
            {{$t('shareNews')}}  
          </button>
          <h3 class="header-main d-flex align-items-center mt-5 mb-3">
            {{$t("latest News")}}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
          <div
            class="row my-2 pointer"
            v-for="(news, i) in getNews"
            :key="news.id * 4 + i"
            @click="goToNews(news.id)"
          >
            <div class="col-4">
              <img :src="news.image_url?news.image_url:placeholder " class="w-100" alt="News Cover" />
            </div>
            <div class="col-8">
              <h6>{{ news.title }}</h6>
            </div>
          </div>
          <h3 class="header-main d-flex align-items-center mt-5 mb-3">
            {{$t('related news')}}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
          <div
            class="row my-2 pointer"
            v-for="news in newsList"
            :key="news.id * 5"
            @click="goToNews(news.id)"
          >
            <div class="col-4">
              <img :src="news.newsCover" class="w-100" alt="News Cover" />
            </div>
            <div class="col-8">
              <h6>{{ news.title }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LastNewsSection
      :title=" $t('related news')"
      desc=" "
      :readMoreBtn="false"
      :lastNews="getNews"
    />
    <transition name="fade">
      <share-box
        v-if="share"
        @close="toggleShareBox"
        :sharing="sharing"
      ></share-box>
    </transition>
  </section>
    <div class="container py-5 my-5" v-else>
  <div class="loader my-5 mx-auto"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LastNewsSection from "../components/LastNews.vue";
import ShareBox from "../components/misc/ShareBox.vue";
export default {
  components: {
    LastNewsSection,
    ShareBox,
  },
  data() {
    return {
      fromatedArticalText: "",
      share: false,
      sharing: {
        url: "",
        title: "",
        description: "",
        quote: "",
        hashtags: "EG_Emabssy,News,New_News",
        twitterUser: "EG_Embassy",
      },
      loading: true,
    };
  },
  watch:{
    $route(nr){
      if(nr){
            this.loadVNews({
      id: nr.params.id,
    }).then(res => {
      this.loading = false;
          this.sharing.url = location.href;
      this.sharing.title = this.getVNews.titles[this.lang];
    this.sharing.description = this.getVNews.descriptions[this.lang];
    })
      }
    }
  },
  mounted() {
    if(!this.$route.params.id) {
      this.$router.push({
        name: 'last-news'
      })
    }
    this.loadVNews({
      id: this.$route.params.id,
    }).then(res => {
      this.loading = false;
          this.sharing.url = location.href;
      this.sharing.title = this.getVNews.titles[this.lang];
    this.sharing.description = this.getVNews.descriptions[this.lang];
    })
  },
  computed: {
    ...mapGetters(["newsList", "lastNews", 'getVNews', 'getNews']),
    artical() {
      const artId = this.$route.params.id;
      const artical = this.newsList.find((art) => art.id == artId);
      return artical;
    },
    articalText() {
      const artical = this.artical;
      return artical.articalText;
    },
  },
  methods: {
    ...mapActions(['loadVNews', 'loadNews']),
    toggleShareBox() {
      this.share = !this.share;
    },
    goToNews(id) {
      if(this.$route.name == 'News-Artical'){
      this.$router.replace({ name: "News-Artical", params: { id: id } });
      }else{
        this.$router.push({ name: "News-Artical", params: { id: id } });
      }
    },
  },
};
</script>

<style>
</style>